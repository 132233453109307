* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: #444;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:normal;
    background-color: #555
}

.card2 {
    display: block;
    height:fit-content;
    width: 80%;
    background-color: #444;
    position: relative;
    box-shadow: -5px 10px 20px 10px rgb(32,32,32);
    font-family: 'Kanit', sans-serif;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
}

.card2 h1 {
    margin-top: 5px;
    font-size: calc(1.8rem + 1.5vw);
    color: aliceblue;
}
.card2 h2 {
    margin-top: 1rem;
    color: aliceblue;
    margin-left: 3rem;
}

.card_content {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    flex-wrap: wrap;
    gap:5px;
    margin: auto;
}

.userdata {
    display: block;
    height: 100px;
    color: aliceblue;
}

.userdata label {
    color: aliceblue;

}

.imageMain2 {
    width: 200px;
    height: auto;
    margin-bottom: 1rem;
}

.imageSub {
    margin: 20px 5px;
    width: 50%;
    height: auto;
}

.input-text {
    position: relative;
    margin-top: 5px;
}

input[type="text"] {
    height: 3rem;
    width: 90%;
    border: none;
    border-radius: 9px;
    border: 1px solid aliceblue;
    color: aliceblue;
    padding: 0 10px;
    padding-left: 30px;
    outline: 0;
    font-size:large;
    display: block;
    margin: auto;
}

.buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center
}

.buttons button {
    height: 3rem;
    width: 90%;
    border: none;
    background-color: orange;
    border-radius: 1rem;
    margin: 5px 15px;
    font-size: 20px;
    outline: 0;
    color: #000;
    cursor: pointer;
    transition: all 0.5s
}

.buttons button:hover {
    box-shadow: 0px 3px 20px 5px rgb(160,160,160,.5);
}

.buttons button:focus {
    box-shadow: 0px 3px 20px 5px rgb(160,160,160,.5);
}

.badge {
    display: block;
    background-color: #555;
}

.printBadge {
    display:block;
}

.demoCanvas {
    box-shadow: 0px 3px 20px 2px rgb(160,160,160,0.8);
}
