:root {
    --warning-bg-color: #ff6666;
    --warning-bg-dark: #bb0000;
    --error-bg-color: #2020ee;
    --error-bg-dark: #0000bb;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: #444;
    font-family: 'Kanit', 'sans-serif';
}

.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items:normal;
    background-color: #555
}

.card {
    height:fit-content;
    width: 80%;
    background-color: #444;
    position: relative;
    box-shadow: -5px 10px 20px 10px rgb(32,32,32);
    font-family: 'Kanit', sans-serif;
    border-radius: 10px;
    padding: 2rem;
    margin: 4rem;
}

.card h1 {
    margin-top: 5px;
    font-size: calc(1.8rem + 1.5vw);
    color: aliceblue;
}
.card h2 {
    margin-top: 1rem;
    color: aliceblue;
    margin-left: 3rem;
}

.images {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    flex-wrap: wrap;
    gap:5px;
    margin: auto;
}

.images label {
    display: block;
    padding: 10px;
    color: aliceblue;
}

.userdata {
    display: block;
    padding: 10px;
    color: aliceblue;
}

.userdata label {
    color: aliceblue;
}

.imageMain {
    max-width: 300px;
    width: 90%;
    height: auto;
}

.imageSub {
    margin: 20px 5px;
    width: 50%;
    height: auto;
}

.input-text {
    position: relative;
    margin-top: 5px;
}

input[type="text"] {
    height: 3rem;
    width: 90%;
    border: none;
    border-radius: 9px;
    border: 1px solid aliceblue;
    color: aliceblue;
    padding: 0 10px;
    padding-left: 30px;
    outline: 0;
    font-size:large;
    display: block;
    margin: auto;
}

.buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center
}

.buttons button {
    height: 3rem;
    width: 90%;
    max-width: 300px;
    border: none;
    background-color: orange;
    border-radius: 1rem;
    margin: 5px 15px;
    font-size: 20px;
    outline: 0;
    color: #000;
    cursor: pointer;
    transition: all 0.5s
}

.buttons button:hover {
    box-shadow: 0px 3px 20px 5px rgb(160,160,160,.5);
}

.buttons button:focus {
    box-shadow: 0px 3px 20px 5px rgb(160,160,160,.5);
}

.badge {
    display: block;
    background-color: #555;
}

.printBadge {
    display:block;
}

.alert_banner {
    width: 75%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px 0px 8px 0px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
}

.red_alert {
    box-shadow: 0 0 30px 1px red;
    background-color: var(--warning-bg-color);
    border-left: 18px solid var(--warning-bg-dark);
}

.blue_alert {
    box-shadow: 0 0 30px 1px blue;
    background-color: var(--error-bg-color);
    border-left: 18px solid var(--error-bg-dark);
}

.alert_banner h4 {
    background: transparent;
    font-size: large;
    color: aliceblue;
}

.quickinfo {
    margin: auto;
    color: aliceblue;
    text-align: right;
    width: 90%;
}
